import { Toaster } from 'react-hot-toast';

import AuthenticatedApp from 'AuthenticatedApp';
import UnauthenticatedApp from 'UnauthenticatedApp';
import { useUser } from 'context/user';

function App() {
  const user = useUser();
  console.log(user);

  return (
    <>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <Toaster position="bottom-right" />
    </>
  );
}

export default App;
