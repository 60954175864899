import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PageSpinner from 'components/PageSpinner';

const Home = lazy(() => import('pages/Home'));
const About = lazy(() => import('pages/About'));
const Brothers = lazy(() => import('pages/Brothers'));
const Rush = lazy(() => import('pages/Rush'));
const Faq = lazy(() => import('pages/Faq'));
const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const NotFound = lazy(() => import('pages/NotFound'));

function UnauthenticatedApp() {
  return (
    <Suspense fallback={<PageSpinner />}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="brothers" element={<Brothers />} />
          <Route path="rush" element={<Rush />} />
          <Route path="faq" element={<Faq />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default UnauthenticatedApp;
