import { createContext, useContext } from 'react';
import { useAuth } from './auth';

const UserContext = createContext();
UserContext.displayName = 'UserContext';

function UserProvider({ children }) {
  const [{ user }] = useAuth();

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within userProvider');
  }
  return context;
}

export { UserProvider, useUser };
