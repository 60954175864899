import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PageSpinner from 'components/PageSpinner';

// const Home = lazy(() => import('pages/Home'));
// const About = lazy(() => import('pages/About'));
// const Brothers = lazy(() => import('pages/Brothers'));
// const Rush = lazy(() => import('pages/Rush'));
// const Faq = lazy(() => import('pages/Faq'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const NotFound = lazy(() => import('pages/NotFound'));

function AuthenticatedApp() {
  return (
    <Suspense fallback={<PageSpinner />}>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="brothers" element={<Brothers />} />
          <Route path="rush" element={<Rush />} />
          <Route path="faq" element={<Faq />} /> */}
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default AuthenticatedApp;
