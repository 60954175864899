import { createContext, useContext, useEffect, useReducer } from 'react';

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

function authReducer(state, action) {
  switch (action.type) {
    case 'SET_SESSION': {
      return {
        ...state,
        session: action.session,
      };
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.user,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ supabaseClient, children }) {
  const [state, dispatch] = useReducer(authReducer, {
    error: null,
    session: null,
    supabase: supabaseClient,
    user: null,
  });

  useEffect(() => {
    const session = supabaseClient.auth.session();

    dispatch({ type: 'SET_SESSION', session: session });
    dispatch({ type: 'SET_USER', user: session?.user ?? null });

    const { data: authListener } = supabaseClient.auth.onAuthStateChange(
      async (_, session) => {
        dispatch({ type: 'SET_SESSION', session: session });
        dispatch({ type: 'SET_USER', user: session?.user ?? null });
      }
    );

    return () => {
      authListener?.unsubscribe();
    };
  }, [supabaseClient.auth]);

  const value = [state, dispatch];

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
